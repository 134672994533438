/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */

export default class TurboFrameErrorRenderer {
  static start() {
    document.addEventListener('turbo:before-fetch-response', (event) => {
      const response = event.detail.fetchResponse.response.clone();

      if (!response.ok) {
        const renderer = new TurboFrameErrorRenderer();
        renderer.renderError(response);
      }
    });
  }

  renderError(response) {
    if (!this.errorTemplate) { return; }

    response.text().then((html) => {
      const payload = new ErrorPayload(html);
      const errorElement = this.assembleErrorElement(response, payload);

      this.flashContainer.appendChild(errorElement);
      this.logBacktrace(response.url, payload.backtrace);
    });
  }

  assembleErrorElement(response, payload) {
    const element = this.errorTemplate.content.cloneNode(true);

    element.querySelector('.status-code').innerText = response.status;
    element.querySelector('.url').innerText = response.url;

    element.querySelector('.exception-title').innerText = payload.exception.title;
    element.querySelector('.exception-description').innerText = payload.exception.description;
    element.querySelector('ul.backtrace').innerHTML = this.constructBacktrace(payload);

    return element;
  }

  constructBacktrace(payload) {
    return payload.backtrace.map((line) => {
      return this.backtraceTemplate.innerHTML.replace(/{content}/g, line);
    }).join('');
  }

  get errorTemplate() {
    return document.querySelector('#turbo-frame-error');
  }

  get backtraceTemplate() {
    const errorTemplate = this.errorTemplate.content.cloneNode(true);
    return errorTemplate.querySelector('template.backtrace');
  }

  get flashContainer() {
    return document.querySelector('#flash_messages');
  }

  logBacktrace(url, backtrace) {
    console.log(
      `Backtrace for 500 on ${url}:\n\n`,
      backtrace.slice(2, -1).join('\n'),
    );
  }
}

class ErrorPayload {
  constructor(html) {
    const parser = new DOMParser();
    this.document = parser.parseFromString(html, 'text/html');
  }

  get exception() {
    if (!this._exception) {
      const element = this.document.body.querySelector('.exception');
      const [title, description] = element.innerText.trim().split(/\n\s+/gm);

      this._exception = {
        title,
        description,
      };
    }

    return this._exception;
  }

  get backtrace() {
    if (!this._backtrace) {
      const element = this.document.body.querySelector('.backtrace .frames');
      this._backtrace = element.innerText.trim().split(/\n\s+/gm);
    }

    return this._backtrace;
  }
}
