import morphdom from 'morphdom';

document.addEventListener('turbo:before-frame-render', (event) => {
  const defaultRender = event.detail.render;

  event.detail.render = (currentElement, newElement) => {
    if (newElement.dataset.morph) {
      morphdom(currentElement, newElement, {
        childrenOnly: true,
        onBeforeElUpdated: (fromEl, toEl) => {
          if (fromEl.dataset.morph === 'false') {
            fromEl.replaceWith(toEl);
            return false;
          }
          return true;
        },
      });
    } else {
      defaultRender(currentElement, newElement);
    }
  };
});
